<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { TruckIcon, GlobeEuropeAfricaIcon, LockClosedIcon } from '@heroicons/vue/24/outline'

const { t } = useI18n()
</script>

<template>
    <section class="bg-white">
        <div class="mx-auto divide-y max-w-7xl divide-gray-2000 lg:flex lg:justify-center lg:divide-y-0 lg:divide-x lg:py-8">
            <div class="py-4 lg:w-1/3 lg:flex-none lg:py-0">
                <div class="flex items-center px-4 mx-auto sm:px-6 lg:px-8">
                    <TruckIcon class="flex-shrink-0 w-8 h-8 text-indigo-600" aria-hidden="true" />

                    <div class="flex flex-col-reverse flex-auto ml-4">
                        <div class="font-medium text-gray-900">{{ t('pages.home.features.free-shipping.title') }}</div>
                        <p class="text-sm text-gray-500">{{ t('pages.home.features.free-shipping.description', { amount: `${$page.props.cart.free_shipping_threshold} €` }) }}</p>
                    </div>
                </div>
            </div>

            <div class="py-4 lg:w-1/3 lg:flex-none lg:py-0">
                <div class="flex items-center px-4 mx-auto sm:px-6 lg:px-8">
                    <GlobeEuropeAfricaIcon class="flex-shrink-0 w-8 h-8 text-indigo-600" aria-hidden="true" />

                    <div class="flex flex-col-reverse flex-auto ml-4">
                        <div class="font-medium text-gray-900">{{ t('pages.home.features.baltic-shipping.title') }}</div>
                        <p class="text-sm text-gray-500">{{ t('pages.home.features.baltic-shipping.description') }}</p>
                    </div>
                </div>
            </div>

            <div class="py-4 lg:w-1/3 lg:flex-none lg:py-0">
                <div class="flex items-center px-4 mx-auto sm:px-6 lg:px-8">
                    <LockClosedIcon class="flex-shrink-0 w-8 h-8 text-indigo-600" aria-hidden="true" />

                    <div class="flex flex-col-reverse flex-auto ml-4">
                        <div class="font-medium text-gray-900">{{ t('pages.home.features.secure-shopping.title') }}</div>
                        <p class="text-sm text-gray-500">{{ t('pages.home.features.secure-shopping.description') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
